import React from 'react'

import ArrowRightSVG from '../../../assets/svg/arrow-right.svg'

const Submit = () => (
  <div className="row">
    <div className="col-12 col-md-9 offset-md-3 col-lg-8 offset-lg-4">
      <div className="c-contactUsForm__footer justify-content-end">
        {/* <div className="c-contactUsFormCaptcha" data-netlify-recaptcha="true"></div> */}
        <button type="submit" className="c-button c-button--secondary">
          <ArrowRightSVG className="mr-2" />
          Send
        </button>
      </div>
    </div>
  </div>
)

export default Submit
