import React from 'react'
import PropTypes from 'prop-types'

const HoneyPot = ({ fieldName }) => (
  <p style={{ visibility: 'hidden', position: 'absolute', left: '-9999px' }}>
    <label>
      Don’t fill this out if you're human: <input name={fieldName} />
    </label>
  </p>
)

HoneyPot.defaultProps = {
  fieldName: 'bot-field',
}

HoneyPot.propTypes = {
  fieldName: PropTypes.string,
}

export default HoneyPot
