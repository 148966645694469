import React from 'react'
import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import HoneyPot from './HoneyPot'

const NetlifyForm = ({
  children,
  className,
  formName,
  successPage,
  fileUpload,
  onSubmit,
}) => {
  const { href: urlQuery } = useLocation()

  const honeyPotFieldName = 'bot-field'

  return (
    <form
      name={formName}
      action={successPage}
      className={className}
      method="POST"
      encType={
        fileUpload ? 'multipart/form-data' : 'application/x-www-form-urlencoded'
      }
      data-netlify="true"
      netlify-honeypot={honeyPotFieldName}
      onSubmit={onSubmit}
    >
      <input type="hidden" name="form-name" value={formName} />
      <input type="hidden" name="form-url" value={urlQuery} />
      <HoneyPot />
      {children}
    </form>
  )
}

NetlifyForm.defaultProps = {
  className: 'c-contactUsForm',
  fileUpload: false,
  onSubmit: null,
}

NetlifyForm.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
  successPage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
}

export default NetlifyForm
