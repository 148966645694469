import React from 'react'
import { Container } from '../../components/elements'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import Newsletter from '../../components/Newsletter'
import DemoForm from '../../components/forms/Demo'

const FADNESignUpPage = () => {
  const breadcrumbs = [
    {
      name: 'FADNE',
      uri: '/fadne',
    },
    {
      name: 'Contact us - FADNE',
      uri: '/fadne/contact-us',
    },
  ]

  return (
    <Page className={`p-sign-up p-forManufacturers has-fingerPrints`}>
      <SEO title="Contact us - FADNE" />
      <Schema breadcrumbs={breadcrumbs} />

      <section className="c-section c-section--pageHeader">
        <Container className="c-section__outer">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <span className="c-pageHeader__title">
                  <strong>FADNE</strong> Contact us
                  </span>
                </div>
                <div className="col-12 col-lg-9">
                  <h1 className="c-pageHeader__intro">
                    Ready to find out how Erudus can work for you?
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="c-section c-section--contact-us">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0 pb-1">
            <div className="c-sectionTitle">
              <div className="row">
                <div className="col-12 col-lg-8 offset-lg-3">
                  <DemoForm formName="FADNE Contact Form" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default FADNESignUpPage